import React, { useState } from "react";
import { ComponentRenderContext } from "../../context";

interface ComponentRenderProviderProps {
    children: React.ReactNode
}

const ComponentRenderProvider: React.FC<ComponentRenderProviderProps> = ({ children }) => {

    const [activeHref, setActiveHref] = useState<string>('/')

    return (
        <ComponentRenderContext.Provider value={{ activeHref, setActiveHref }}>
            {
                children
            }
        </ComponentRenderContext.Provider>
    )
}

export default ComponentRenderProvider