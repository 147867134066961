import axios from "axios";

export default class Util {
    static async parseMetadata(uri: string) {
        try {
            const metaData = await axios.get(uri);
            return metaData.data;
        } catch (error) {
            console.log(error);
            // throw error
        }
    }

    static toNumber(value: any) {
        return Number(value)
    }

    static numberToHex(number: number) {
        return number.toString(16);
    }

    static parseFileType(file: string) {
        const candidates = file.split(".");

        return candidates[candidates.length - 1]
    }

    static replaceSubstring(supString: string) {
        if (supString?.indexOf('ipfs://') > -1) {
            return supString.replace('ipfs://', 'https://ipfs.io/ipfs/')
        }
        else if (supString?.indexOf('ar://') > -1) {
            return supString.replace('ar://', 'https://arweave.net/')
        }
        else {
            return supString
        }
    }

    static prettyStringify(jsonObject: object): string {
        const _1 = JSON.stringify(jsonObject, null, 4);
        const _2 = _1.replace("\", \"", "\",\n\"");

        return _2
    }

    static truncateEthereumAddress(address: string, chars: number = 4): string {
        const prefix = address.slice(0, 2); // Retrieve the first two characters
        const suffix = address.slice(-chars); // Retrieve the last `chars` characters

        return `${prefix}...${suffix}`;
    }

    static numFormatter(num: any, flag = false) {
        const isNumber = (n: any) => {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        };
        if (isNumber(num)) {
            if (num >= 900 && num < 1000000) {
                return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
            } else if (num < 1000000000 && num >= 1000000) {
                return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
            } else if (num < 900) {
                if (flag) {
                    return Number(num).toFixed(1); // if value < 1000, nothing to do
                } else {
                    return Number(num).toFixed(2); // if value < 1000, nothing to do
                }
            }
        } else return 0;
    }

    static timestampToDate(timestamp: string | number) {
        return new Date(timestamp).toISOString().slice(0, 10);
    }

    static fromShares(amount: string) {
        return amount.substr(0, amount.length - 6);
    }

    static fromDays(x: number) {
        return x / (60 * 60 * 24);
    }

    static stamp2date(event: any, timeStamp: any) {
        if (!event) return null;
        let currentStamp = Date.now();
        let targetDate = this.timestampToDate(currentStamp - (timeStamp - event) * 1000);
        return targetDate;
    }

    static getLeftDays = (start: any, end: any) => {
        let timeStamp = Date.now();
        if (start === "0") {
            return "Not funded yet";
        } else if (end < timeStamp) {
            return "Ended";
        } else {
            let leftDays = Math.floor(this.fromDays(Number(end) - Number(timeStamp)));
            return `${leftDays} days left`;
        }
    }

    static getFundingLeftDays = (start: number, end: number) => {
        let timeStamp = Date.now();
        if (start > timeStamp) {
            return "Launching";
        } else if (end < timeStamp) {
            return `Ended`;
        } else {
            let leftDays = Math.floor(this.fromDays(Number(end) - Number(timeStamp)));
            return `${leftDays} days left`;
        }
    };

    static getEventDate(event: any) {
        let timeStamp = Date.now();
        if (event.start === "0") return null;
        else if (event.end > timeStamp) {
            return `Began: ${this.stamp2date(event.start, timeStamp)}`;
        } else {
            return this.stamp2date(event.end, timeStamp);
        }
    }

    static getFundingState = (start: number, end: number) => {
        let timeStamp = Date.now();
        if (start > timeStamp) {
            return "Boiling";
        } else if (end < timeStamp) {
            return `Stale`;
        } else {
            return `Active`;
        }
    };

    static string2Fixed(string: string) {
        if (string) return parseFloat(string).toFixed(2);
        else return "0.00";
    }
}