import { axios2 } from "../lib";

class PaymentAction {
    static verifyHashkey = async ( chainId: number, hashKey: string, domain: string ) => {
        try {
            const data = { chainId, hashKey, domain }
            const response = await axios2.post('/verify-key', data)

            return response.data
        }
        catch(error) {
            throw error
        }
    }
}

export default PaymentAction