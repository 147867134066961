// ** import external libraries
import React, { useState, useEffect } from "react"
import { useNetwork, useAccount } from "wagmi"
import Card from "./common/Card"
import PoolList from "./data/PoolList"
import CreateModal from "./modal/CreateModal"

// ** import custom action & types
import { StakingAction } from "../action"
import { userPool } from "../type"
import { Util } from "../helper"

const LeaderBoard = () => {
    const { chain } = useNetwork()
    const { address } = useAccount()

    const [modalOpened, setModalOpened] = useState<boolean>(false)

    const [totalStaked, setTotalStaked] = useState<number>(0)
    const [totalReward, setTotalReward] = useState<number>(0)

    const handleModalOpen = () => setModalOpened(true)
    const handleModalClose = () => setModalOpened(false)

    useEffect(() => {
        (async () => {
            try {
                if(chain && address) {
                    const data: userPool = await StakingAction.getUserPools(chain?.id, address)

                    const poolUSDStakings = data.user.pools.map(pool => Util.toNumber(pool.stakedUSD))
                    const poolUSDRewards = data.user.pools.map(pool => Util.toNumber(pool.rewardsUSD))

                    const totalStakedUSD = poolUSDStakings.reduce((acc, crr) => acc + crr, 0)
                    const totalRewardUSD = poolUSDRewards.reduce((acc, crr) => acc + crr, 0)

                    setTotalStaked(totalStakedUSD)
                    setTotalReward(totalRewardUSD)
                }
            }
            catch(error) {
                console.error(error)
            }
        })()
    }, [chain, address])

    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex w-[100%] justify-between gap-[10px]">
                <Card title='Total Staked' content={`$${Util.numFormatter(totalStaked.toFixed(2))}`} />
                <Card title='Total Unclaimed Rewards' content={`$${Util.numFormatter(totalReward.toFixed(2))}`} />
            </div>
            <div className="flex w-full justify-between gap-[10px]">
                <PoolList />
            </div>
            <div className="flex w-full justify-end">
                <button
                    onClick={handleModalOpen}
                    className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]" 
                    type="button"
                >
                    Create
                </button>
            </div>
            <CreateModal open={modalOpened} onClose={handleModalClose} />
        </div>
    )
}

export default LeaderBoard