// ** import external libraries
import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@nextui-org/react'
// ** import custom components
import CustomCarousel from '../common/CustomCarousel'
import NFTGalleryItem from './NFTGalleryItem'
import NFTListGallery from './NFTListGallery'
// ** import custom actions
import AlchemyAction from '../../action/Alchemy'
// ** import custom constants
import { KEY_STORE } from '../../constant'

import type { OwnedNft } from 'alchemy-sdk'

interface NftExplorerPropTypes {
    chainId: number
    account: string
    onTokenImport: (tokenAddress: string, tokenId: string) => void
}

const NftExplorer: React.FC<NftExplorerPropTypes> = ({ chainId, account, onTokenImport }) => {

    const [validNFTs, setValidNFTs] = useState<OwnedNft[]>([])
    const [isListView, setIsListView] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            try {
                const alchemy = new AlchemyAction(KEY_STORE.ALCHEMY_KEY, chainId)

                const result = await alchemy.getWalletNFTs(account)

                setValidNFTs(result)

                console.log(result)
            }
            catch (error) {
                console.log(error)
            }
        })()
    }, [chainId])

    return (
        <div className='px-[0px]'>
            <div className='px-[0px]'>
                <Grid.Container>
                    <Grid xs={12}>
                        <Button.Group color='primary' ghost size='sm'>
                            <Button style={isListView ? { width: '90px', borderColor: '#bbbbbb', backgroundColor: 'transparent', color: 'black' } : { width: '90px', borderColor: '#bbbbbb', backgroundColor: '#bbbbbb', color: 'black' }} onPress={() => setIsListView(false)}>Carousel</Button>
                            <Button style={isListView ? { width: '90px', borderColor: '#bbbbbb', backgroundColor: '#bbbbbb', color: 'black' } : { width: '90px', borderColor: '#bbbbbb', backgroundColor: 'transparent', color: 'black' }} onPress={() => setIsListView(true)}>List</Button>
                        </Button.Group>
                    </Grid>
                </Grid.Container>
            </div>
            <div className='px-[0px]'>
                {isListView ?
                    <NFTListGallery onTokenImport={onTokenImport} nftList={validNFTs} />
                    :
                    <CustomCarousel>
                        {
                            validNFTs.map((nft, index) => <NFTGalleryItem onTokenImport={onTokenImport} key={index} nft={nft} />)
                        }
                    </CustomCarousel>
                }
            </div>
        </div>
    )
}

export default NftExplorer