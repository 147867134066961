import React from "react";
import StakingWidget from "../components/widget";

interface StakingWidgetPageProps{
    chainId: number
    appId: string
    domain: string
}
const StakingWidgetPage: React.FC<StakingWidgetPageProps> = ({ chainId, appId, domain }) => {

    return (
        <div className="mb-10 h-[100dvh]">
            <StakingWidget chainId={chainId} appId={appId} domain={domain} />
        </div>
    );
};

export default StakingWidgetPage;