const MESSAGE = {
    ERROR: {
        CONTEXT: `Context can be used only in provider`,
        NOT_ERC1155CONTRACT: `Inputted address is not ERC1155 contract address`,
        INVALID_STAKING_TYPE: `Can't recognize the staking type`,
        INVALID_POOL_TYPE: `Can't recognize the pool type`,
        UKNOWN_ERROR: `Unknown error occurred`,
        FORCE_IMPORT: `Please fill all required fields`,
        NO_FUNDINGS: `This pool has no funding history to clear`
    },
    SUCCESS: {
        STAKED: `Successfully staked`,
        UNSTAKED: `Successfully unstaked`,
        CLAIMED: `Successfully claimed`,
        POOL_CREATED: `pool was successfully created`,
        POOL_FUNDED: `Funding successfully completed`,
        POOL_CLEAND: `Pool was successfully cleaned`,
        COMPETITIVE_CREATED: `Competitive pool was successfully created`,
        FRIENDLY_CREATED: `Friendly pool was successfully created`,
        ARQUARIUM_CREATED: `Aquarium pool was successfully created`,
        ERC1155CONTRACT_DETECTED: `ERC1155 Contract was detected`
    }
}

export default MESSAGE