const ENDPOINT = {
  BASE_URL: `https://www.stakeapi.fanbase.io`,
  PAYMENT_BASE_URL: `https://dashboard.fanbase.io`,
  API_VERSION: {
    V1: "/v1",
    V2: "/v2",
  },
  UNISWAP_TOKEN_LIST: `https://gateway.ipfs.io/ipns/tokens.uniswap.org`,
  RAMP_TEST: `https://app.demo.ramp.network`,
  RAMP_PRODUCTION: `https://app.demo.ramp.network`
};

export default ENDPOINT;
