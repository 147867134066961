import { Outlet } from "react-router-dom";
import { Container } from "@nextui-org/react";

const MainLayout = () => {
  return (
    <div className="w-full h-[100dvh]">
      <Outlet />
    </div>
  );
};

export default MainLayout;
