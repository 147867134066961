interface NetworkConstantProps {
  CHAINID_TO_NAME: {
    [key: number]: string;
  };
  CHAINID_TO_RPC: {
    [key: number]: string;
  };
  CHAINID_TO_SUBGRAPH: {
    [key: number]: string;
  };
  CHAINID_TO_GEYSER_ADDRESS: {
    [key: number]: string;
  };
}

const NETWORK_CONSTANT: NetworkConstantProps = {
  CHAINID_TO_NAME: {
    1: "Mainnet",
    5: "Goerli Test Network",
    10: "Optimism Main Network",
    56: "BNB Smart Chain",
    137: "Polygon Main Network",
  },
  CHAINID_TO_RPC: {
    1: "https://rpc.ankr.com/eth",
    5: "https://rpc.ankr.com/eth_goerli",
    10: "https://mainnet.optimism.io",
    56: "https://bsc-dataseed1.ninicoin.io",
    137: "https://polygon-rpc.com",
  },
  CHAINID_TO_SUBGRAPH: {
    1: "https://api.thegraph.com/subgraphs/name/fanbaseeu/fanbase-network",
    5: "https://api.thegraph.com/subgraphs/name/cryptopanda430/fanbasegoerli",
    10: "https://api.thegraph.com/subgraphs/name/fanbaseeu/optimism-network",
    56: "https://api.thegraph.com/subgraphs/name/fanbaseeu/bnb-mainnet",
    137: "https://api.thegraph.com/subgraphs/name/fanbaseeu/polygon",
  },
  CHAINID_TO_GEYSER_ADDRESS: {
    1: "0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5",
    5: "0xC883D4f7dAD93d4B7325Ed2B83eD56FD95E73c42",
    10: "0xfCDCBA599b2261f878CC9c244b2814FaE47F9b5B",
    56: "0x5370653C8A065cA4393Ffd3F892BD49178D85ced",
    137: "0x1d47536d368010A98343fF98Aa22BCB4B9d1a5BD",
  },
};

export default NETWORK_CONSTANT;
