import React from "react";
import _StakingWidget_ from "./StakingWidget";
import WidgetProvider from "../context/WidgetContext";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "../connector/getLibrary";
import ProviderWrapper from "../components/provider/ProviderWrapper";
import ComponentRenderProvider from "../components/provider/ComponentRenderProvider";

declare global {
    interface Window {
        ethereum?: any;
    }
}

interface StakingWidgetPropTypes {
    chainId: number
    appId: string
    domain: string
}

export const StakingWidget: React.FC<StakingWidgetPropTypes> = ({ chainId, appId, domain }) => {
    return (
        <WidgetProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
                <ProviderWrapper>
                    <ComponentRenderProvider>
                        <_StakingWidget_ chainId={chainId} appId={appId} domain={domain} />
                    </ComponentRenderProvider>
                </ProviderWrapper>
            </Web3ReactProvider>
        </WidgetProvider>
    );
};