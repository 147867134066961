import React from "react";

interface TokenLogoProps {
    token: any
    size?: string | number
}

const TokenLogo: React.FC<TokenLogoProps> = ({ token, size = "30px" }) => {
    const { logoURI, symbol } = token;
    return (
        <div className="token-logo-wrap">
            {logoURI && (
                <img
                    alt="token"
                    style={{ width: size, flexShrink: "0", borderRadius: "50%" }}
                    src={logoURI}
                />
            )}
            {!logoURI && symbol && (
                <span className="pool-token-logo">{symbol.substr(0, 3)}</span>
            )}
            {!logoURI && !symbol && <span className="pool-token-logo">{"ERC"}</span>}
        </div>
    );
};

export default TokenLogo