import React from "react";
import { Link } from "react-router-dom";
import { IMAGE } from "../../constant";

const Banner: React.FC = () => {
  return (
    <div className="mt-6 mb-2 mx-auto">
      <Link to="https://www.fanbase.io/#/about" target="_blank">
        <img src={IMAGE.LOGO} className="w-[180px]" />
      </Link>
    </div>
  );
};

export default Banner;
