import { ethers, Eip1193Provider, JsonRpcSigner, BrowserProvider } from "ethers"

class Web3Helper {
    /**
     * 
     * @param {Eip1193Provider} connector 
     * @returns {Promise<JsonRpcSigner>}
     */
    static getSignerFromInjectedProvider = async (connector: Eip1193Provider) => {
        try {
            const provider = new ethers.BrowserProvider(connector)
            const signer = await provider.getSigner()

            return signer
        }
        catch(error) {
            throw error
        }
    }

    /**
     * 
     * @param {Eip1193Provider} connector 
     * @returns {BrowserProvider}
     */
    static getRpcProviderInjectedProvider = (connector: Eip1193Provider) => {
        try {
            const provider = new ethers.BrowserProvider(connector)

            return provider
        }
        catch(error) {
            throw error
        }
    }
}

export default Web3Helper