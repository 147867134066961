import React from "react"

interface CardProps {
    title: string
    content: string
}

const Card: React.FC<CardProps> = ({ title, content }) => {
    return (
        <div className="block max-w-sm w-[100%] p-6 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-gray-700 dark:text-white text-center">{ title }</h5>
            <h1 className="text-2xl font-bold text-gray-500 text-center">{ content }</h1>
        </div>
    )
}

export default Card