import { axios } from "../lib"

export default class StakingAction {
    static async getPools(chainId: number, first: number, skip: number, orderBy: string, orderDirection: "asc" | "desc") {
        try {
            const response = await axios.get(`/pools?chainId=${chainId}&first=${first}&skip=${skip}&orderBy=${orderBy}&orderDirection=${orderDirection}`)
            
            return response.data.data
        }
        catch (error) {
            throw error
        }
    }

    static async getUserPools(chainId: number, address: string) {
        try {
            const response = await axios.get(`/user-pool?chainId=${chainId}&address=${address}`)

            return response.data.data
        }
        catch (error) {
            throw error
        }
    }

    static async getPoolDetail(chainId: number, id: string) {
        try {
            const response = await axios.get(`/pool-detail?chainId=${chainId}&id=${id}`)

            return response.data.data
        }
        catch (error) {
            throw error
        }
    }

    static async getUserStake(chainId: number, address: string) {
        try {
            const response = await axios.get(`/user-stake?chainId=${chainId}&address=${address}`)

            return response.data.data
        }
        catch (error) {
            throw error
        }
    }

    static async createCompetitivePool(chainId: number, stakingTokenAddress: string, rewardTokenAddress: string, minBonus: number, maxBonus: number, period: number) {
        try {
            const data = { chainId, stakingTokenAddress, rewardTokenAddress, minBonus, maxBonus, period }
            const response = await axios.post('/create-competitive-pool', data)

            return response.data.data
        }
        catch (error) {
            throw error
        }
    }

    static async createFriendlyPool(chainId: number, stakingTokenAddress: string, rewardTokenAddress: string, bonus: number, period: number) {
        try {
            const data = { chainId, stakingTokenAddress, rewardTokenAddress, bonus, period }
            const response = await axios.post('/create-friendly-pool', data)

            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async createArquariumPool(chainId: number, stakingTokenAddress: string, rewardTokenAddress: string, bonus: number, period: number) {
        try {
            const data = { chainId, stakingTokenAddress, rewardTokenAddress, bonus, period }
            const response = await axios.post('/create-arquarium-pool', data)

            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async createSftPool(chainId: number, stakingTokenAddress: string, rewardTokenAddress: string, bonus: number, period: number) {
        try {
            const data = { chainId, stakingTokenAddress, rewardTokenAddress, bonus, period }
            const response = await axios.post('/create-sft-pool', data)

            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async fundErc20CompetitiveWithStartDate(chainId: number, poolAddress: string, rewardTokenAddress: string, amount: string, startDate: string, period: number) {
        try {
            const data = { chainId, poolAddress, rewardTokenAddress, amount, startDate, period }        
            const response = await axios.post('/timed-fund-competitive', data)
            
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async fundFriendlyWithStartDate(chainId: number, poolAddress: string, rewardTokenAddress: string, amount: string, startDate: string, period: number) {
        try {
            const data = { chainId, poolAddress, rewardTokenAddress, amount, startDate, period }        
            const response = await axios.post('/timed-fund-friendly', data)

            return response.data.data
        }
        catch(error) {
            throw error
        }
    }
    
    static async fundErc20Competitive(chainId: number, poolAddress: string, rewardTokenAddress: string, amount: string, period: number) {
        try {
            const data = { chainId, poolAddress, rewardTokenAddress, amount, period }        
            const response = await axios.post('/fund-competitive', data)
            
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }
    
    static async fundFriendly(chainId: number, poolAddress: string, rewardTokenAddress: string, amount: string, period: number) {
        try {
            const data = { chainId, poolAddress, rewardTokenAddress, amount, period }        
            const response = await axios.post('/fund-friendly', data)
            
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async cleanPool(chainId: number, poolAddress: string) {
        try {
            const data = { chainId, poolAddress }
            const response = await axios.post('/clean', data)
            
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }
    
    static async stake(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenAmount: string, wfnbAmount: string, multiplier: boolean) {
        console.log({ chainId, poolAddress, stakingTokenAddress, tokenAmount, wfnbAmount, multiplier })
        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenAmount, wfnbAmount, multiplier }
            const response = await axios.post('/stake', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async stakeAquarium(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenIds: Array<number>, wfnbAmount: string, multiplier: boolean) {
        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenIds, wfnbAmount, multiplier }
            const response = await axios.post('/stake-aquarium', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async stakeSft(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenId: number, tokenAmount: string, wfnbAmount: string, multiplier: boolean, operator: string) {

        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenId, tokenAmount, wfnbAmount, multiplier, operator }

            console.log(data)

            const response = await axios.post('/stake-sft', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async unstakeSft(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenId: number, tokenAmount: string, wfnbAmount: string, multiplier: boolean, operator: string) {

        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenId, tokenAmount, wfnbAmount, multiplier, operator }

            console.log(data)

            const response = await axios.post('/unstake-sft', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async unstakeAquarium(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenIds: Array<number>, wfnbAmount: string, multiplier: boolean) {
        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenIds, wfnbAmount, multiplier }

            console.log(data)

            const response = await axios.post('/unstake-arquarium', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async unstake(chainId: number, poolAddress: string, stakingTokenAddress: string, tokenAmount: string, wfnbAmount: string, multiplier: boolean) {

        try {
            const data = { chainId, poolAddress, stakingTokenAddress, tokenAmount, wfnbAmount, multiplier }

            const response = await axios.post('/unstake', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async claimGeyserV2(chainId: number, poolAddress: string, tokenAmount: string, wfnbAmount: string) {
        try {
            const data = { chainId, poolAddress, tokenAmount, wfnbAmount }

            console.log(data)

            const response = await axios.post('/claim-geyserv2', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async claimFountain(chainId: number, poolAddress: string, tokenAmount: string) {
        try {
            const data = { chainId, poolAddress, tokenAmount }

            console.log(data)

            const response = await axios.post('/claim-fountain', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async claimAquarium(chainId: number, poolAddress: string, tokenIds: Array<number>) {

        try {
            const data = { chainId, poolAddress, tokenIds }

            const response = await axios.post('/claim-arquarium', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }

    static async claimSft(chainId: number, poolAddress: string, tokenId: number, tokenAmount: string) {
        try {
            const data = { chainId, poolAddress, tokenId, tokenAmount }

            console.log(data)

            const response = await axios.post('/claim-sft', data)
    
            return response.data.data
        }
        catch(error) {
            throw error
        }
    }
}