// ** import external libraries
import React, { useEffect, useState } from "react"
import { Input } from "antd"
import Swal from "sweetalert2"
import { useNetwork, useAccount, useWalletClient } from "wagmi"
import { ethers } from "ethers"

// ** import custom libraries & types
import { StakingAction } from "../../action"
import { MESSAGE } from "../../constant"
import { pool, transaction, stakeType } from "../../type"
import { useWidget } from "../../context/WidgetContext"
import { Util, Web3Helper } from "../../helper"

interface UnStakeProps {
    pool: pool
}

const UnStake: React.FC<UnStakeProps> = ({ pool }) => {
    const provider = Web3Helper.getRpcProviderInjectedProvider(window?.ethereum!)

    const { chain } = useNetwork()
    const { address } = useAccount()
    const { data: walletClient } = useWalletClient()
    const { appLoading, setAppLoading } = useWidget()

    const [poolType, setPoolType] = useState<stakeType>()
    const [tokenAmount, setTokenAmount] = useState<number>(0)
    const [wfnbAmount, setWfnbAmount] = useState<number>(0)
    const [multiplier, setMultiplier] = useState<boolean>(true)
    const [transactions, setTransactions] = useState<Array<transaction>>()
    const [tokenIds, setTokenIds] = useState<Array<number>>()
    const [tokenId, setTokenId] = useState<number>()

    const onTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setTokenAmount(Util.toNumber(e.target.value))
    const onWfnbAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setWfnbAmount(Util.toNumber(e.target.value))
    const onTokenIdListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTokenIds(e.target.value.split(",").filter(c => c !== " ").map(id => {
            return Util.toNumber(id)
        }))
    }
    const onTokenIdChange = (e: React.ChangeEvent<HTMLInputElement>) => setTokenId(Util.toNumber(e.target.value))

    const handleUnStake = async () => {
        const tokenAmountParam = ethers.parseUnits(tokenAmount.toString(), pool.staking_token.decimals || 18).toString()
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address) {
            try {
                const transactions = await StakingAction.unstake(chain.id, pool.address, pool.staking_token.id, tokenAmountParam, wfnbAmountParam, multiplier)

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.STAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleUnStakeAquarium = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenIds) {
            try {
                const transactions = await StakingAction.unstakeAquarium(chain.id, pool.address, pool.staking_token.id, tokenIds, wfnbAmountParam, multiplier)
                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.UNSTAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleUnStakeSft = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenId !== undefined) {
            try {
                const transactions = await StakingAction.unstakeSft(chain.id, pool.address, pool.staking_token.id, tokenId, tokenAmount.toString(), wfnbAmountParam, multiplier, address)

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.UNSTAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleClaimGeyserV2 = async () => {
        const tokenAmountParam = ethers.parseUnits(tokenAmount.toString(), pool.staking_token.decimals || 18).toString()
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address) {
            try {
                const transactions = await StakingAction.claimGeyserV2(chain.id, pool.address, tokenAmountParam, wfnbAmountParam)

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.CLAIMED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleClaimFountain = async () => {
        try {
            const tokenAmountParam = ethers.parseUnits(tokenAmount.toString(), pool.staking_token.decimals || 18).toString()

            if (chain && address) {
                try {
                    const transaction = await StakingAction.claimFountain(chain.id, pool.address, tokenAmountParam)

                    try {
                        if (walletClient && transaction) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                            Swal.fire("Success", MESSAGE.SUCCESS.CLAIMED, "success")
                        }
                        else {
                            console.log(transactions)
                            Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                        }
                    }
                    catch (error) {
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                        console.error(error)
                    }
                }
                catch (error) {
                    console.error(error)
                }
            }
            else {
                Swal.fire("Oops...", "Please connect wallet and try again", "warning")
            }
        }
        catch (error) {
            throw error
        }
    }

    const handleClaimAquarium = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenIds) {
            try {
                const transactions = await StakingAction.claimAquarium(chain.id, pool.address, tokenIds)
                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.CLAIMED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleClaimSft = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenId !== undefined) {
            try {
                const transactions = await StakingAction.claimSft(chain.id, pool.address, tokenId, tokenAmount.toString())

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.CLAIMED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleConfirmUnStake = async () => {
        if (poolType === "Aquarium") {
            await handleUnStakeAquarium()
        }
        else if (poolType === "Fountain") {
            await handleUnStake()
        }
        else if (poolType === "GeyserV2") {
            await handleUnStake()
        }
        else if (poolType === "SFT") {
            await handleUnStakeSft()
        }
        else {
            Swal.fire("Error", MESSAGE.ERROR.INVALID_POOL_TYPE, "error")
        }
    }

    const handleConfirmClaim = async () => {
        if (poolType === "Aquarium") {
            await handleClaimAquarium()
        }
        else if (poolType === "Fountain") {
            await handleClaimFountain()
        }
        else if (poolType === "GeyserV2") {
            await handleClaimGeyserV2()
        }
        else if (poolType === "SFT") {
            await handleClaimSft()
        }
        else {
            Swal.fire("Error", MESSAGE.ERROR.INVALID_POOL_TYPE, "error")
        }
    }

    // Set pool type
    useEffect(() => {
        setPoolType(pool.poolType as stakeType)
    }, [pool])

    // Set multiplier depending on network
    useEffect(() => {
        if (chain?.testnet === false && chain?.id !== 1) {
            setMultiplier(false);
        }
    }, [chain])

    const renderStakeFormByType = () => {
        if (poolType === "Aquarium") {
            return (
                <div className="flex w-full justify-center gap-3">
                    <Input prefix="Token Ids: " onChange={onTokenIdListChange} suffix={pool.staking_token.symbol} />
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmClaim}
                    >
                        Claim
                    </button>
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmUnStake}
                    >
                        UnStake
                    </button>
                </div>
            )
        }
        else if (poolType === "Fountain") {
            return (
                <div className="flex w-full justify-center gap-3">
                    <Input prefix="Token Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmClaim}
                    >
                        Claim
                    </button>
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmUnStake}
                    >
                        UnStake
                    </button>
                </div>
            )
        }
        else if (poolType === "GeyserV2") {
            return (
                <div className="flex w-full justify-center gap-3">
                    <Input prefix="Token Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmClaim}
                    >
                        Claim
                    </button>
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmUnStake}
                    >
                        UnStake
                    </button>
                </div>
            )
        }
        else if (poolType === "SFT") {
            return (
                <div className="flex w-full justify-center gap-3">
                    <Input prefix="Token ID: " onChange={onTokenIdChange} />
                    <Input prefix="Token Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmClaim}
                    >
                        Claim
                    </button>
                    <button
                        className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                        type="button"
                        onClick={handleConfirmUnStake}
                    >
                        UnStake
                    </button>
                </div>
            )
        }
        else {
            <div>
                <h1>
                    {MESSAGE.ERROR.INVALID_POOL_TYPE}
                </h1>
            </div>
        }
    }

    return (
        <div>
            <div className="flex w-full justify-center mt-[30px] mb-[50px]">
                {
                    renderStakeFormByType()
                }
            </div>
        </div>
    )
}

export default UnStake