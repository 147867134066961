import React from 'react'

interface ComponentRenderContextProps {
    activeHref: string
    setActiveHref: React.Dispatch<React.SetStateAction<string>>
}

const ComponentRenderContext = React.createContext<ComponentRenderContextProps>({
    activeHref: '/',
    setActiveHref: () => {}
})

export default ComponentRenderContext