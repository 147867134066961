// ** import external libraries
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { MdDashboard, MdSearch, MdCurrencyBitcoin, MdCollections } from 'react-icons/md'
import { useAccount, useNetwork } from 'wagmi'

// ** import custom libraries and components
import TopSettingBar from '../components/layout/TobSettingBar'
import Banner from '../components/layout/Banner'
import LeaderBoard from '../components/LeaderBoard'
import ScoutBoard from '../components/ScoutBoard'
import StakeBoard from '../components/StakeBoard'
import NftBoard from '../components/NftBoard'
import { PaymentAction } from '../action'
import { ROLE } from '../constant'

interface StakingWidgetPropTypes {
    chainId: number
    appId: string
    domain: string
}

const StakingWidget: React.FC<StakingWidgetPropTypes> = ({ chainId, appId, domain }) => {
    const { address } = useAccount()
    const { chain } = useNetwork()

    const [userRole, setUserRole] = useState<number>(ROLE.BLOCK_ROLE)

    /**
     * API Key verification
     */
    useEffect(() => {
        (async () => {
            console.log(chainId, appId, domain)
            try {
                const role = await PaymentAction.verifyHashkey(chainId, appId, domain)
                setUserRole(role.data)
            }
            catch (error) {
                setUserRole(ROLE.BLOCK_ROLE)
                console.error(error)
            }
        })()
    }, [chain, address])

    return (
        <div className="flex flex-col justify-between w-full h-[100dvh] mx-auto bg-white rounded-xl text-[#263238] p-6">
            {
                userRole >= ROLE.RENDER_ROLE ?
                    <>
                        <TopSettingBar title='Staking' />
                        <Tabs
                            defaultActiveKey='1'
                            tabBarStyle={{ width: '100%' }}
                            items={[
                                {
                                    label: <span className='flex'><MdDashboard size={20} />Dashboard</span>,
                                    key: '1',
                                    children: <LeaderBoard />,
                                },
                                {
                                    label: <span className='flex'><MdSearch size={20} />&nbsp;Scout</span>,
                                    key: '2',
                                    children: <ScoutBoard />
                                },
                                {
                                    label: <span className='flex'><MdCurrencyBitcoin size={20} />Stake</span>,
                                    key: '3',
                                    children: <StakeBoard />
                                },
                                {
                                    label: <span className='flex'><MdCollections size={20} />&nbsp;NFT Explorer</span>,
                                    key: '4',
                                    children: <NftBoard />
                                }
                            ]}
                        />
                        {
                            userRole === ROLE.REMOVE_LOGO_ROLE ? <></> : <Banner />
                        }
                    </>
                    :
                    <h1 className='flex'>Invalid Access key</h1>
            }
        </div>
    )
}

export default StakingWidget