// ** import external libraries
import React, { useEffect, useState } from "react"
import { Input } from "antd"
import Swal from "sweetalert2"
import { useNetwork, useAccount, useWalletClient } from "wagmi"
import { ethers } from "ethers"

// ** import custom libraries & types
import { StakingAction } from "../../action"
import { MESSAGE } from "../../constant"
import { pool, transaction, stakeType } from "../../type"
import { useWidget } from "../../context/WidgetContext"
import { Util, Web3Helper } from "../../helper"

interface StakeProps {
    pool: pool
}

const Stake: React.FC<StakeProps> = ({ pool }) => {
    const provider = Web3Helper.getRpcProviderInjectedProvider(window?.ethereum!)

    const { chain } = useNetwork()
    const { address } = useAccount()
    const { data: walletClient } = useWalletClient()
    const { appLoading, setAppLoading } = useWidget()

    const [poolType, setPoolType] = useState<stakeType>()
    const [tokenAmount, setTokenAmount] = useState<number>(0)
    const [wfnbAmount, setWfnbAmount] = useState<number>(0)
    const [multiplier, setMultiplier] = useState<boolean>(true)
    const [transactions, setTransactions] = useState<Array<transaction>>()
    const [tokenIds, setTokenIds] = useState<Array<number>>()
    const [tokenId, setTokenId] = useState<number>()

    const onTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setTokenAmount(Util.toNumber(e.target.value))
    const onWfnbAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setWfnbAmount(Util.toNumber(e.target.value))
    const onTokenIdListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTokenIds(e.target.value.split(",").filter(c => c !== " ").map(id => {
            return Util.toNumber(id)
        }))
    }
    const onTokenIdChange = (e: React.ChangeEvent<HTMLInputElement>) => setTokenId(Util.toNumber(e.target.value))

    const handleStake = async () => {
        const tokenAmountParam = ethers.parseUnits(tokenAmount.toString(), pool.staking_token.decimals || 18).toString()
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address) {
            try {
                const transactions = await StakingAction.stake(chain?.id, pool.address, pool.staking_token.id, tokenAmountParam, wfnbAmountParam, multiplier)

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.STAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleStakeAquarium = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenIds) {
            try {
                const transactions = await StakingAction.stakeAquarium(chain.id, pool.address, pool.staking_token.id, tokenIds, wfnbAmountParam, multiplier)
                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.STAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleStakeSft = async () => {
        const wfnbAmountParam = ethers.parseUnits(wfnbAmount.toString(), 18).toString()

        if (chain && address && tokenId !== undefined) {
            try {
                const transactions = await StakingAction.stakeSft(chain.id, pool.address, pool.staking_token.id, tokenId, tokenAmount.toString(), wfnbAmountParam, multiplier, address)

                try {
                    if (walletClient && transactions) {
                        for await (const transaction of transactions) {
                            const hash = await walletClient.sendTransaction(transaction)
                            const tx = await provider.waitForTransaction(hash)
                            console.log("Confirmed:", tx)
                        }
                        Swal.fire("Success", MESSAGE.SUCCESS.STAKED, "success")
                    }
                    else {
                        console.log(transactions)
                        Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    }
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const handleConfirmStake = async () => {
        if (poolType === "Aquarium") {
            await handleStakeAquarium()
        }
        else if(poolType === "Fountain") {
            await handleStake()
        }
        else if (poolType === "GeyserV2") {
            await handleStake()
        }
        else if (poolType === "SFT") {
            await handleStakeSft()
        }
        else {
            Swal.fire("Error", MESSAGE.ERROR.INVALID_POOL_TYPE, "error")
        }
    }

    useEffect(() => {
        setPoolType(pool.poolType as stakeType)
    }, [pool])

    useEffect(() => {
        if (chain?.testnet === false && chain?.id !== 1) {
            setMultiplier(false);
        }
    }, [chain])

    const renderStakeFormByType = () => {
        if (poolType === "Aquarium") {
            return (
                <div className="flex w-full justify-center gap-5">
                    <Input prefix="Token IDs: " onChange={onTokenIdListChange} />
                    <Input prefix="WFNB Amount: " onChange={onWfnbAmountChange} suffix="WFNB" />
                </div>
            )
        }
        else if (poolType === "Fountain") {
            return (
                <div className="flex w-full justify-center gap-5">
                    <Input prefix="Staking Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <Input prefix="WFNB Amount: " onChange={onWfnbAmountChange} suffix="WFNB" />
                </div>
            )
        }
        else if (poolType === "GeyserV2") {
            return (
                <div className="flex w-full justify-center gap-5">
                    <Input prefix="Staking Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <Input prefix="WFNB Amount: " onChange={onWfnbAmountChange} suffix="WFNB" />
                </div>
            )
        }
        else if (poolType === "SFT") {
            return (
                <div className="flex w-full justify-center gap-5">
                    <Input prefix="Token ID: " onChange={onTokenIdChange} />
                    <Input prefix="Amount: " onChange={onTokenAmountChange} suffix={pool.staking_token.symbol} />
                    <Input prefix="WFNB: " onChange={onWfnbAmountChange} suffix="WFNB" />
                </div>
            )
        }
        else {
            <div>
                <h1>
                    {MESSAGE.ERROR.INVALID_POOL_TYPE}
                </h1>
            </div>
        }
    }

    return (
        <div>
            {renderStakeFormByType()}
            <div className="flex w-full justify-end mt-[30px]">
                <button
                    className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                    type="button"
                    onClick={handleConfirmStake}
                >
                    Stake
                </button>
            </div>
        </div>
    )
}

export default Stake