// ** import external libraries
import React, { useEffect, useState } from "react";
import { Table, TableColumnsType } from 'antd'
import { useAccount } from "wagmi";
import { useNetwork } from "wagmi";

// ** import custom components
import TokenLogo from "../common/TokenLogo";
import FundModal from "../modal/FundModal";

// ** import custom libraries & types
import { StakingAction } from "../../action";
import { Util } from "../../helper";
import type { pool, userPool, funding, token4stake } from "../../type";

interface FundingListProps {
    stakeToken?: token4stake
    rewardToken?: token4stake
    history?: Array<funding>
}

const FundingList: React.FC<FundingListProps> = ({ stakeToken, rewardToken, history }) => {
    const columns: TableColumnsType<funding> = [
        {
            title: 'Rewards',
            dataIndex: 'shares',
            align: 'center',
            render: (amount, row) => <span>{Util.numFormatter(Util.fromShares(amount))} {rewardToken?.symbol}</span>
        },
        {
            title: 'Locked',
            dataIndex: 'shares',
            sorter: {
                compare: (a: any, b: any) => a.english - b.english,
                multiple: 3,
            },
            align: 'center',
            render: (amount, row) => <span>{Util.numFormatter(Util.fromShares(amount))} {stakeToken?.symbol}</span>
        },
        {
            title: 'Unlocked',
            dataIndex: 'shares',
            sorter: {
                compare: (a: any, b: any) => a.math - b.math,
                multiple: 2,
            },
            align: 'center',
            render: (amount, row) => <span>{Util.numFormatter(Util.fromShares(amount))} {stakeToken?.symbol}</span>
        },
        {
            title: 'Schedule',
            dataIndex: 'end',
            responsive: ['xl'],
            align: 'center',
            render: (end, row) => <span>{Util.getFundingLeftDays(Util.toNumber(row?.start), end)}</span>,
        },
        {
            title: "Status",
            responsive: ["sm"],
            dataIndex: "end",
            render: (end, row) => <span>{Util.getFundingState(Util.toNumber(row?.start), end)}</span>,
        },
    ];

    return (
        <div className="w-full">
            <Table
                columns={columns}
                dataSource={history} 
                pagination={{ defaultPageSize: 3 }} 
                scroll={{ x: 200 }}  
                onRow={
                    (record) => {
                        return { onClick: () => console.log(record)}
                    }
                }
            />
        </div>
    )
}

export default FundingList