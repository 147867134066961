import { Alchemy } from 'alchemy-sdk'
import AlchemyChain from '../connector/alchemyChain'

import { TOKEN_TYPE } from '../constant'

export default class AlchemyAction {

    alchemy: Alchemy

    constructor(apiKey: string, chainId: number) {
        this.alchemy = new Alchemy({
            apiKey,
            network: AlchemyChain[chainId]
        })
    }

    async getWalletNFTs(address: string) {
        try {
            const response = await this.alchemy.nft.getNftsForOwner(address)

            return response.ownedNfts
        }
        catch(error) {
            throw error
        }
    }

    async getWallet1155NFTs(address: string) {
        try {
            const response = await this.alchemy.nft.getNftsForOwner(address)

            return response.ownedNfts.filter(nft => nft.tokenType === TOKEN_TYPE.ERC1155)
        }
        catch(error) {
            throw error
        }
    }
}