// ** import external libraries
import React, { useEffect, useState } from "react";
import { Table, TableColumnsType } from 'antd'
import { useAccount } from "wagmi";
import { useNetwork } from "wagmi";

// ** import custom components
import TokenLogo from "../common/TokenLogo";
import FundModal from "../modal/FundModal";

// ** import custom libraries & types
import { StakingAction } from "../../action";
import { Util } from "../../helper";
import type { pool, userPool } from "../../type";

interface PoolListPropTypes {
}

const PoolList: React.FC<PoolListPropTypes> = () => {
    const { chain } = useNetwork()
    const { address } = useAccount()

    const [rows, setRows] = useState<pool[] | undefined>()

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedPoolData, setSelectedPoolData] = useState<pool>()

    const onCloseModal = () => {
        setModalOpen(false)
    }

    const onRowSelect = (record: pool) => {
        console.log(record)
        setSelectedPoolData(record)
        setModalOpen(true)
    }

    const columns: TableColumnsType<pool> = [
        {
            title: 'TVL',
            dataIndex: 'tvl',
            sorter: {
                compare: (a: any, b: any) => a - b,
                multiple: 3,
            },
            align: 'center',
            render: (amount) => <span>${Util.numFormatter(amount)}</span>
        },
        {
            title: 'Staking Token',
            dataIndex: 'staking_token_symbol',
            sorter: {
                compare: (a: any, b: any) => a.english - b.english,
                multiple: 3,
            },
            align: 'center',
            render: (amount, row) => (
                <div style={{ display: "flex" }}>
                    <TokenLogo token={row?.staking_token} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                        }}
                    >
                        <span>
                            {row?.staking_token?.symbol ? row?.staking_token?.symbol : "ERC1155"}
                        </span>
                        <span style={{ fontSize: "11px" }}>
                            {Util.numFormatter(amount, true)}
                            {` ($${Util.numFormatter(row?.staked_usd)})`}
                        </span>
                    </div>
                </div>
            ),
            // width
        },
        {
            title: 'Rewards',
            dataIndex: 'reward_token_symbol',
            sorter: {
                compare: (a: any, b: any) => a.math - b.math,
                multiple: 2,
            },
            align: 'center',
            render: (amount, row) => (
                <div style={{ display: "flex" }}>
                    <TokenLogo token={row?.reward_token} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                        }}
                    >
                        <span>
                            {row?.reward_token.symbol ? row?.reward_token.symbol : "ERC1155"}
                        </span>
                        <span style={{ fontSize: "11px" }}>
                            {Util.numFormatter(amount, true)}
                            {` ($${Util.numFormatter(row?.rewards_usd)})`}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Reward Rate',
            dataIndex: 'reward_rate',
            sorter: {
                compare: (a: any, b: any) => a.english - b.english,
                multiple: 1,
            },
            align: 'center',
            render: (value) => <span>{Util.string2Fixed(value)}% APR</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: {
                compare: (a: any, b: any) => a.english - b.english,
                multiple: 1,
            },
            align: 'center'
        },
    ];

    useEffect(() => {
        (async () => {
            if(chain?.id && address) {
                try {
                    const pool: userPool = await StakingAction.getUserPools(chain?.id, address)

                    const poolList: pool[] = pool.user.pools.map((poolItem, index) => (
                        {
                            key: index,
                            address: poolItem.id,
                            poolType: poolItem.poolType,
                            start: poolItem.start,
                            end: poolItem.end,
                            tvl: Number(poolItem.tvl).toFixed(3),
                            staking_token_symbol: poolItem.stakingToken.symbol,
                            reward_token_symbol: poolItem.rewardToken.symbol,
                            staking_token: poolItem.stakingToken,
                            reward_token: poolItem.rewardToken,
                            reward_rate: poolItem.apr,
                            type: poolItem.poolType,
                            status: poolItem.state,
                            staked_usd: poolItem.stakedUSD,
                            rewards_usd: poolItem.rewardsUSD
                        }
                    ))
        
                    setRows(poolList)
                }
                catch(error) {
                    console.error(error)
                }
            }
        })()
    }, [address, chain])

    return (
        <div className="w-full">
            <Table
                columns={columns}
                dataSource={rows} 
                pagination={{ defaultPageSize: 3 }} 
                scroll={{ x: 200 }}  
                onRow={
                    (record) => {
                        return { onClick: () => onRowSelect(record)}
                    }
                }
            />
            <FundModal open={modalOpen} onClose={onCloseModal} pool={selectedPoolData} />
        </div>
    )
}

export default PoolList