import { useRoutes, useSearchParams } from "react-router-dom"
import MainLayout from "../layouts/main"
import StakingWidgetPage from "../pages/StakingWidgetPage"

const Router = () => {
    const [searchParams] = useSearchParams()

    const accessKey = searchParams.getAll('api_key')[0]
    const domain = searchParams.getAll('sup_domain')[0]
    const chainId = searchParams.getAll('chain_id')[0]

    return (
        useRoutes([
            {
                path: "/",
                element: <MainLayout />,
                children: [
                    {
                        path: "/staking",
                        element: <StakingWidgetPage appId={accessKey} domain={domain} chainId={Number(chainId)} />
                    }
                ]
            }
        ])
    )
}

export default Router