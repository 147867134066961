// ** import external libraries & types
import React, { useEffect, useState } from "react"
import { Modal, Radio, Input, Slider, Tabs, DatePicker } from "antd"
import { useNetwork, useAccount, useWalletClient } from "wagmi"
import Swal from "sweetalert2"
import { ethers } from "ethers"

// ** import custom components
import Card from "../common/Card"
import Stake from "../innermodal/Stake"
import UnStake from "../innermodal/UnStake"

// ** import custom libraries
import { Util, Web3Helper } from "../../helper"
import { StakingAction } from "../../action"
import { useWidget } from "../../context/WidgetContext"
// ** import custom constants & types
import { MESSAGE } from "../../constant"
import { poolType, transaction, pool, poolDetail, stakeType } from "../../type"

interface StakeModalProps {
    open?: boolean
    onClose?: () => void
    pool?: pool
}

const StakeModal: React.FC<StakeModalProps> = ({ open, pool, onClose }) => {
    const provider = Web3Helper.getRpcProviderInjectedProvider(window?.ethereum!)

    const { chain } = useNetwork()
    const { address } = useAccount()
    const { data: walletClient } = useWalletClient()
    const { appLoading, setAppLoading } = useWidget()

    const [poolDetail, setPoolDetail] = useState<poolDetail>()
    const [amount, setAmount] = useState<number>(0)
    const [period, setPeriod] = useState<number>(0)
    const [transaction, setTransaction] = useState<transaction>()
    const [transactions, setTransactions] = useState<Array<transaction>>()

    const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setAmount(Util.toNumber(e.target.value))
    const onPeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => setPeriod(Util.toNumber(e.target.value))

    const onConfirmFund = async () => {
        if (!!!period || !!!amount) {
            Swal.fire("Error", MESSAGE.ERROR.FORCE_IMPORT, "error")
            return
        }

        if (chain?.id && address && pool) {
            const fundAmount = ethers.parseUnits(amount.toString(), pool?.reward_token.decimals || 18).toString()
            setAppLoading(true)

            if (poolDetail?.pool?.rewardModuleType === "ERC20Competitive") {
                try {
                    const transactions = await StakingAction.fundErc20Competitive(chain.id, pool?.address, pool?.reward_token.id, fundAmount, period)
                    console.log("CompetitiveTransaction:", transactions)
                    setTransactions(transactions)
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            else if (poolDetail?.pool?.rewardModuleType === "ERC20Friendly") {
                try {
                    const transactions = await StakingAction.fundFriendly(chain.id, pool?.address, pool?.reward_token.id, fundAmount, period)
                    console.log("FriendlyTransaction:", transactions)
                    setTransactions(transactions)
                }
                catch (error) {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                    console.error(error)
                }
            }
            else {
                Swal.fire("Error", MESSAGE.ERROR.INVALID_STAKING_TYPE, "error")
            }

            try {
                if (walletClient && transactions) {
                    for await (const transaction of transactions) {
                        const hash = await walletClient.sendTransaction(transaction)
                        const tx = await provider.waitForTransaction(hash)
                        console.log("Confirmed:", tx)
                    }

                    Swal.fire("Success", MESSAGE.SUCCESS.POOL_FUNDED, "success")
                }
                else {
                    console.log(transactions)
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                }
            }
            catch (error) {
                Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    const onCleanPool = async () => {
        try {
            if(!!!poolDetail?.pool?.fundings?.length) {
                Swal.fire("Error", MESSAGE.ERROR.NO_FUNDINGS, "error")
                return
            }

            if(chain && address && pool) {
                const transaction = await StakingAction.cleanPool(chain?.id, pool?.address)
                if (walletClient) {
                    const hash = await walletClient.sendTransaction(transaction)
                    const tx = await provider.waitForTransaction(hash)
                    console.log("Confirmed:", tx)
                }
                else {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                }
            }
            else {
                Swal.fire("Oops...", "Please connect wallet and try again", "warning")
            }
        }
        catch(error) {
            Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
            console.error(error)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (chain && pool) {
                    const poolData = await StakingAction.getPoolDetail(chain?.id, pool?.address)
                    setPoolDetail(poolData)
                }
                else {
                    console.log(chain, pool)
                }
            }
            catch (error) {
                console.error(error)
            }
        })()
    }, [pool])

    return (
        <Modal
            title={
                <h1 className="text-2xl">STAKE</h1>
            }
            width={600}
            open={open}
            onCancel={onClose}
            footer={null}
        >
            <div className="flex w-full gap-5 justify-center mt-[30px]">
                <Card title="Staked" content={`${Util.numFormatter(poolDetail?.pool?.staked)} ${poolDetail?.pool?.stakingToken?.symbol}`} />
                <Card title="Remaining" content={`${Util.numFormatter(poolDetail?.pool?.rewards)} ${poolDetail?.pool?.rewardToken?.symbol}`} />
                <Card title="APR" content={`${Util.toNumber(poolDetail?.pool?.apr).toFixed(2)}%`} />
            </div>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ width: '100%' }}
                items={[
                    {
                        key: "1",
                        label: "Stake",
                        children: <Stake pool={pool!} />
                    },
                    {
                        key: "2",
                        label: "UnStake",
                        children: <UnStake pool={pool!} />
                    }
                ]}
            />
        </Modal>
    )
}

export default StakeModal