import React, { useEffect, useState } from "react"
import { useNetwork, useAccount } from "wagmi"

import Card from "./common/Card"
import StakeList from "./data/StakeList"

import { StakingAction } from "../action"
import { userPool } from "../type"
import { Util } from "../helper"

const StakeBoard = () => {

    const { chain } = useNetwork()
    const { address } = useAccount()

    const [totalStaked, setTotalStaked] = useState<number>(0)
    const [totalReward, setTotalReward] = useState<number>(0)

    useEffect(() => {
        (async () => {
            try {
                if(chain && address) {
                    const data: userPool = await StakingAction.getUserPools(chain?.id, address)

                    const poolUSDStakings = data.user.pools.map(pool => Util.toNumber(pool.stakedUSD))
                    const poolUSDRewards = data.user.pools.map(pool => Util.toNumber(pool.rewardsUSD))

                    const totalStakedUSD = poolUSDStakings.reduce((acc, crr) => acc + crr, 0)
                    const totalRewardUSD = poolUSDRewards.reduce((acc, crr) => acc + crr, 0)

                    setTotalStaked(totalStakedUSD)
                    setTotalReward(totalRewardUSD)
                }
            }
            catch(error) {
                console.error(error)
            }
        })()
    }, [chain, address])

    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex w-[100%] justify-between gap-[10px]">
                <Card title='Your total stake' content={`$${Util.numFormatter(totalStaked.toFixed(2))}`} />
                <Card title='Your unclaimed rewards' content={`$${Util.numFormatter(totalReward.toFixed(2))}`} />
            </div>
            <div className="flex w-full justify-between gap-[10px]">
                <StakeList />
            </div>
        </div>
    )
}

export default StakeBoard