// ** import external libraris
import React, { useState } from "react"
import { useNetwork, useAccount } from "wagmi"
// ** import custom components
import NftExplorer from "./nft/NftExplorer"
import CreateModal from "./modal/CreateModal"


const NftBoard = () => {
    const { chain } = useNetwork()
    const { address } = useAccount()

    const [modalOpened, setModalOpened] = useState<boolean>(false)

    const handleModalOpen = () => setModalOpened(true)
    const handleModalClose = () => setModalOpened(false)

    return (
        <>
            <NftExplorer chainId={ chain?.id || 1 } account={address || "0x6F6a66f29B005b9db247c0b147F7D518477c90a8"} onTokenImport={handleModalOpen} />
            <CreateModal open={modalOpened} onClose={handleModalClose} />
        </>
    )
}

export default NftBoard