// ** import external libraries & types
import React, { useState} from "react"
import { Modal, Radio, Input, Slider } from "antd"
import type { RadioChangeEvent } from "antd"
import { useNetwork, useAccount, useWalletClient} from "wagmi"
import Swal from "sweetalert2"

// ** import custom libraries
import { Web3Helper } from "../../helper"
import { StakingAction } from "../../action"

// ** import custom constants & types
import { MESSAGE } from "../../constant"
import { poolType, transaction } from "../../type"

interface CreateModalProps {
    open?: boolean
    onClose?: () => void
}

const CreateModal: React.FC<CreateModalProps> = ({ open, onClose }) => {

    const provider = Web3Helper.getRpcProviderInjectedProvider(window?.ethereum!)

    const { chain } = useNetwork()
    const { address } = useAccount()
    const { data: walletClient } = useWalletClient()

    const [poolType, setPoolType] = useState<poolType>("COMPETITIVE")
    const [stakingTokenAddress, setStakingTokenAddress] = useState<string>("")
    const [rewardTokenAddress, setRewardTokenAddress] = useState<string>("")
    const [period, setPeriod] = useState<number>(0)
    const [transaction, setTransaction] = useState<transaction>()

    const [rewardPercentage, setRewardPercentage] = useState<number>(0)
    const [rewardPercentRange, setRewardPercentRange] = useState<[number, number]>([2, 10])

    const onChangeType = (e: RadioChangeEvent) => setPoolType(e.target.value)
    const onChangeStakingTokenAddress = (e: React.ChangeEvent<HTMLInputElement>) => setStakingTokenAddress(e.target.value)
    const onChangeRewardTokenAddress = (e: React.ChangeEvent<HTMLInputElement>) => setRewardTokenAddress(e.target.value)
    const onChangePeriod = (e: React.ChangeEvent<HTMLInputElement>) => setPeriod(parseInt(e.target.value))
    const onChangeRewardPercentage = (value: number) => setRewardPercentage(value)
    const onChangeRewardPercentRange = (value: [number, number]) => setRewardPercentRange(value)

    const handleCreatePool = async () => {
        if(chain?.id && address) {
            if(poolType === "COMPETITIVE") {
                try {
                    const data = await StakingAction.createCompetitivePool(chain.id, stakingTokenAddress, rewardTokenAddress, rewardPercentRange[0], rewardPercentRange[1], period)
                    setTransaction(data)
                }
                catch(error) {
                    console.error(error)
                }
            }
            else if(poolType === "FRIENDLY") {
                try {
                    const data = await StakingAction.createFriendlyPool(chain.id, stakingTokenAddress, rewardTokenAddress, rewardPercentage, period)
                    setTransaction(data)
                }
                catch(error) {
                    console.error(error)
                }
            }
            else if(poolType === "NFT") {
                try {
                    const data = await StakingAction.createArquariumPool(chain.id, stakingTokenAddress, rewardTokenAddress, rewardPercentage, period)
                    setTransaction(data)
                }
                catch(error) {
                    console.error(error)
                }
            }
            else if(poolType === "NFT_ADVANCED") {
                try {
                    const data = await StakingAction.createSftPool(chain.id, stakingTokenAddress, rewardTokenAddress, rewardPercentage, period)
                    setTransaction(data)
                }
                catch(error) {
                    console.error(error)
                }
            }
            else {
                Swal.fire("Error", MESSAGE.ERROR.INVALID_STAKING_TYPE, "error")
            }

            try {
                if(walletClient && transaction) {
                    const hash = await walletClient.sendTransaction(transaction)
                    const tx = await provider.waitForTransaction(hash)
                    console.log("Confirmed:", tx)

                    Swal.fire("Success", poolType + MESSAGE.SUCCESS.POOL_CREATED, "success")
                }
                else {
                    Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                }
            }
            catch(error) {
                Swal.fire("Error", MESSAGE.ERROR.UKNOWN_ERROR, "error")
                console.error(error)
            }
        }
        else {
            Swal.fire("Oops...", "Please connect wallet and try again", "warning")
        }
    }

    return (
        <Modal
            title={
                <h1 className="text-2xl">Create Pool</h1>
            }
            width={600}
            open={open}
            onCancel={onClose}
            footer={null}
        >
            <div className="flex w-full justify-center mt-[30px]">
                <Radio.Group className="w-full" defaultValue="COMPETITIVE" onChange={onChangeType}>
                    <Radio.Button value="COMPETITIVE" className="w-[25%] text-center">Competitive</Radio.Button>
                    <Radio.Button value="FRIENDLY" className="w-[25%] text-center">Friendly</Radio.Button>
                    <Radio.Button value="NFT" className="w-[25%] text-center">NFT</Radio.Button>
                    <Radio.Button value="NFT_ADVANCED" className="w-[25%] text-center">NFT Advanced</Radio.Button>
                </Radio.Group>
            </div>
            <div className="flex flex-col w-full justify-center gap-[5px] mt-[30px]">
                <h1 className="text-xl font-semibold">Input Tokens</h1>
                <Input prefix="Stake Token: " onChange={onChangeStakingTokenAddress} />
                <Input prefix="Reward Token: " onChange={onChangeRewardTokenAddress} />
            </div>
            <div className="flex flex-col w-full justify-center gap-[5px] mt-[30px]">
                <h1 className="text-xl font-semibold">Reward Setting</h1>
                {
                    poolType === "COMPETITIVE" ?
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row justify-between">
                            <h2 className="flex">From: {rewardPercentRange[0]}x</h2>
                            <h2 className="flex">To: {rewardPercentRange[1]}x</h2>
                        </div>
                        <Slider range defaultValue={[2, 10]} value={rewardPercentRange} min={0} max={100} step={1} onChange={onChangeRewardPercentRange} />
                    </div>
                    :
                    <div className="flex flex-col w-full">
                        <h2>Reward Percentage: {rewardPercentage}%</h2>
                        <Slider min={0} max={100} value={rewardPercentage} onChange={onChangeRewardPercentage} />
                    </div>
                }
                <div className="flex flex-row w-[50%]">
                    <Input prefix="Reward Period: " suffix="days" onChange={onChangePeriod} />
                </div>
            </div>
            <div className="flex w-full justify-end">
                <button
                    className="flex items-center justify-center text-sm w-[100px] h-10 mr-2 py-1 px-3 rounded-md font-bold bg-[#0162d0] text-white shadow-sm ring-1 ring-inset ring-[#0353ad] hover:bg-[#0353ad]"
                    type="button"
                    onClick={handleCreatePool}
                >
                    Confirm
                </button>
            </div>
        </Modal>
    )
}

export default CreateModal